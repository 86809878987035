import axios from 'axios';

export default {
    addIncidentExport(tenantId, exportRequest) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Exports', exportRequest)
    },

    getExportsForIncident(tenantId, incidentId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Incidents/' + incidentId + '/Exports')
    },

    getExportUrl(tenantId, exportId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Exports/' + exportId + '/Media');
    },

    getExport(tenantId, exportId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Exports/' + exportId);
    }
}