<template>
  <div>
    <h3 v-if="apiClientsToShow.length">Share With</h3>

    <!-- Is loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Is loading -->

    <!-- Nothing to share with -->
    <a-alert
      v-if="!isLoading && apiClients.length == 0"
      message="Your organisation does not currently have any third party integrations enabled"
      description="teamSOS can automatically create incidents in leading platforms such as CPOMS and MyConcern, just ask for details."
      type="info"
      show-icon
    />
    <!-- / Nothing to share with -->

    <!-- Share with -->
    <div class="api-clients">
      <api-client-card
        v-for="apiClient in apiClientsToShow"
        :key="apiClient.id"
        :api-client="apiClient"
        :tenant-id="tenantId"
        :incident="incident"
        :org-id="incident.ownerId"
        :my-presence-id="myPresenceId"
      ></api-client-card>
    </div>
    <!-- / Share with -->
  </div>
</template>

<script>
import apiClients from "../../../../../api/api-clients";
import { mapGetters } from "vuex";
import ApiClientCard from "./ShareWith/ApiClientCard.vue";
const _ = require("lodash");
export default {
  components: { ApiClientCard },
  data() {
    return {
      isLoadingApiClients: false,
      apiClients: [],
    };
  },
  computed: {
    ...mapGetters("incidentViewer", {
      tenantId: "tenantId",
      incident: "incident",
      sharedWithApiClientIds: "sharedWithApiClientIds",
      myPresenceId: "myPresenceId",
    }),

    isLoading() {
      return this.isLoadingApiClients;
    },

    apiClientsToShow() {
      let vm = this;
      let withLinkTarget = _.filter(this.apiClients, (client) => {
        return client.linkTargetId;
      });
      let alsoNotShared = _.filter(withLinkTarget, (client) => {
        return !vm.sharedWithApiClientIds.includes(client.id);
      });
      return alsoNotShared;
    },
  },
  created() {
    this.loadApiClients();
  },
  methods: {
    loadApiClients() {
      let vm = this;
      vm.isLoadingApiClients = true;
      apiClients
        .getApiClients(this.tenantId, this.incident.ownerId)
        .then((r) => {
          vm.isLoadingApiClients = false;
          vm.apiClients = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingApiClients = false;
          // vm.$message.error("Error loading API clients");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  margin-top: 20px;
}

.api-clients {
  margin-top: 15px;
}
</style>