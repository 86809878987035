<template>
  <div class="incident">
    <!-- Loading -->
    <div v-if="isLoading" class="text-center loading-wrapper">
      <a-spin />
    </div>
    <!-- / Loading -->

    <!-- No incident -->
    <div v-if="!isLoading && !incident" class="no-incident-wrapper">
      <a-alert type="error" message="Error loading incident, please try again." />
    </div>
    <!-- / No incident -->

    <!-- Incident loaded -->
    <div v-if="!isLoading && incident" class="incident-inner">
      <a-layout id="incident-show-inner-layout">
        <a-layout :style="{ background: '#f7f8fa' }">
          <a-layout-content @mouseover="collapseRightSidebar" :style="{ padding: '20px 30px' }">
            <div class="incident-wrapper">
              <div class="top">
                <div class="page-header">
                  <h1 class="page-title" v-if="!isEditingName">
                    <a-button type="default" style="display: inline; margin-right: 15px" v-if="adminMode || fromAdmin"
                      @click.prevent="goBack" icon="left"></a-button>

                    <admin-org-indicator v-if="adminMode || fromAdmin"></admin-org-indicator>

                    {{ incident.displayName }}
                  </h1>
                  <div class="header-inline-actions" v-if="!isEditingName">
                    <a-button @click.prevent="startEditingName" size="small" class="header-inline-action"
                      icon="edit"></a-button>
                  </div>
                  <a-input @keyup.enter="saveNewName" size="large" class="name-input" v-model="newName"
                    v-if="isEditingName" :disabled="isSavingName"></a-input>
                  <div class="header-inline-actions" v-if="isEditingName">
                    <a-button @click.prevent="saveNewName" :disabled="isSavingName || !newName.length" type="primary"
                      icon="check" size="large"></a-button>
                    <a-button :disabled="isSavingName" @click.prevent="cancelEditingName" type="danger" icon="cross"
                      size="large"></a-button>
                  </div>
                  <incident-tags :incident="incident"></incident-tags>
                  <!-- <close-incident-button-and-modal
                    :incident="incident"
                    :my-presence-id="myPresenceId"
                    :tenant-id="tenantId"
                  ></close-incident-button-and-modal> -->
                  <pin-button v-if="!isArchived" size="small" :guid="incident.id"></pin-button>

                  <mute-button v-if="!isArchived" size="small" :guid="incident.id"></mute-button>

                  <div class="actions">
                    <div class="actions-inner">
                      <close-incident-button-and-modal v-if="!isArchived" :incident="incident"
                        :my-presence-id="myPresenceId" :tenant-id="tenantId"
                        @navigate-to-escalate="navigateToEscalate"></close-incident-button-and-modal>

                      <delete-incident-button-and-modal v-if="isArchived" :incident="incident"
                        :my-presence-id="myPresenceId" :tenant-id="tenantId"
                        :admin-mode="adminMode"></delete-incident-button-and-modal>
                    </div>
                  </div>
                </div>
                <a-tabs v-model="selectedTab">
                  <a-tab-pane v-if="isReseller" key="reseller-overview" tab="Overview">
                  </a-tab-pane>
                  <a-tab-pane v-if="!isReseller" key="messages" tab="Messages">
                  </a-tab-pane>
                  <a-tab-pane v-if="!isReseller && !isArchived" key="help-videos" tab="Help Videos"></a-tab-pane>
                  <a-tab-pane v-if="!isReseller" key="tasks" :tab="tasksTabTitle">
                  </a-tab-pane>
                  <a-tab-pane key="escalate" tab="Escalate"> </a-tab-pane>
                  <a-tab-pane v-if="isArchived" key="exports" tab="Export">
                  </a-tab-pane>
                </a-tabs>
              </div>

              <!-- Waiting for responder modal -->
              <waiting-for-responder-modal :my-presence-id="myPresenceId"
                v-if="justCreatedIncident && isWaitingForFirstResponder"></waiting-for-responder-modal>
              <!-- / Waiting for responder modal -->

              <!-- Send location modal -->
              <send-location-modal :my-presence-id="myPresenceId" v-if="justCreatedIncident"></send-location-modal>
              <!-- / Send location modal -->

              <!-- Overview (Reseller Mode) -->
              <div v-if="selectedTab == 'reseller-overview'">
                <div class="reseller-overview-inner" :key="resellerUpdateKey">
                  <!-- <expanded-sidebar></expanded-sidebar> -->
                  <reseller-overview></reseller-overview>
                </div>
              </div>
              <!-- / Overview (Reseller Mode) -->

              <!-- Messages -->
              <div class="middle" v-if="selectedTab == 'messages'">
                <timeline :key="`timeline-${incident.id}`" :membership="membership" :owner-type="3"
                  :owner-id="incident.id" :timeline="timelineGroupedByDate" :my-presence-id="myPresenceId"
                  :tenant-id="tenantId" @view-tasks="viewTasks"></timeline>
              </div>
              <div class="bottom" v-if="selectedTab == 'messages'">
                <typing-indicators :my-presence-id="myPresenceId"></typing-indicators>

                <!-- Doesn't have presence (admin, not archived) -->
                <div class="bottom-toolbar-wrapper" v-if="!amIPresentInGroup && !isArchived">
                  <div class="add-me-to-group">
                    <div class="left">
                      <b>You are not a member of this incident...</b>
                    </div>
                    <div class="right">
                      <a-button :disabled="isAddingStaffMembers" @click.prevent="addMeToIncident" type="primary"
                        size="large">
                        Add Yourself To Incident
                      </a-button>
                    </div>
                  </div>
                </div>
                <!-- / Doesn't have presence (admin) -->

                <!-- Has Presence (not archived) -->
                <div class="bottom-toolbar-wrapper" v-if="amIPresentInGroup && !isArchived">
                  <div class="left">
                    <send-message-actions @add-checklist="addChecklist" @add-staff-members="addStaffMembers"
                      :membership="membership" :tenant-id="tenantId" :org-id="incident.ownerId"
                      :incident-id="incident.id"
                      :my-presence-id="myPresenceId"></send-message-actions>
                  </div>
                  <div class="right">
                    <send-message :my-presence-id="myPresenceId"></send-message>
                  </div>
                </div>
                <!-- / Has Presence -->

                <!-- Is archived -->
                <div class="bottom-toolbar-wrapper" v-if="isArchived">
                  <div class="is-archived" :key="timeAgoRefreshKey">
                    <b>Reason for archiving: </b>{{ incident.archivedNote }}
                    <br />
                    Archived by {{ archivedBy }}
                    {{ displayTimeAgo(incident.archived) }}
                  </div>
                </div>
                <!-- / Is archived -->
              </div>
              <!-- / Messages -->

              <!-- Help Videos -->
              <div class="middle" v-if="selectedTab == 'help-videos'">
                <help-videos></help-videos>
              </div>
              <!-- / Help Videos -->

              <!-- Tasks -->
              <div class="middle" v-show="selectedTab == 'tasks'">
                <tasks v-if="!isArchived"></tasks>
                <archived-tasks v-if="isArchived"></archived-tasks>
              </div>
              <!-- / Tasks -->

              <!-- Escalate -->
              <div class="middle" v-if="selectedTab == 'escalate'">
                <escalate @response-team-added="handleResponseTeamAdded" @add-staff-members="addStaffMembers"
                  :membership="membership" :tenant-id="tenantId" :org-id="incident.ownerId"
                  :my-presence-id="myPresenceId" :admin-mode="adminMode"></escalate>
              </div>
              <!-- / Escalate -->

              <!-- Exports -->
              <div class="middle" v-if="selectedTab == 'exports'">
                <exports></exports>
              </div>
              <!-- / Exports -->
            </div>
          </a-layout-content>
        </a-layout>
        <a-layout-sider v-if="!isReseller" width="300" id="incident-show-right-sidebar" style="background: #fff"
          v-model="rightbarCollapsed" collapsible reverse-arrow theme="light">
          <collapsed-sidebar v-if="rightbarCollapsed" @expand="expandRightSidebar"></collapsed-sidebar>

          <expanded-sidebar v-if="!rightbarCollapsed" @collapse="collapseRightSidebar">
          </expanded-sidebar>
        </a-layout-sider>
      </a-layout>
    </div>
    <!-- / Incident loaded -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const _ = require("lodash");
import CollapsedSidebar from "./Show/CollapsedSidebar.vue";
import ExpandedSidebar from "./Show/ExpandedSidebar.vue";
import ResellerOverview from "./Show/ResellerOverview.vue";
import SendMessage from "../../components/Timelines/SendMessage.vue";
import Timeline from "../../components/Timelines/Timeline.vue";
import HelpVideos from "./Show/Tabs/HelpVideos.vue";
import SendMessageActions from "../../components/SendMessageActions.vue";
import incidents from "../../api/incidents";
import assignments from "../../api/assignments";
import archivedIncidents from "../../api/archived-incidents";
import Tasks from "./Show/Tabs/Tasks.vue";
import ArchivedTasks from "./Show/Tabs/ArchivedTasks.vue";
import Escalate from "./Show/Tabs/Escalate.vue";
import IncidentTags from "../../components/Incidents/IncidentTags.vue";
import SendLocationModal from "./Show/SendLocationModal.vue";
import WaitingForResponderModal from "./Show/WaitingForResponderModal.vue";
import UpdatesLastReadPointer from "../../mixins/UpdatesLastReadPointer";
import TypingIndicators from "../../components/TypingIndicators.vue";
import Dates from "../../mixins/Dates";
import TimeAgoRefresher from "../../mixins/TimeAgoRefresher";
import Exports from "./Show/Tabs/Exports.vue";
import PinButton from "../../components/PinButton.vue";
import MuteButton from "../../components/MuteButton.vue";
import CloseIncidentButtonAndModal from "./Show/CloseIncidentButtonAndModal.vue";
import DeleteIncidentButtonAndModal from "./Show/DeleteIncidentButtonAndModal.vue";

export default {
  name: "Show",
  components: {
    CollapsedSidebar,
    SendMessage,
    ExpandedSidebar,
    Timeline,
    HelpVideos,
    SendMessageActions,
    ArchivedTasks,
    Tasks,
    Escalate,
    IncidentTags,
    SendLocationModal,
    WaitingForResponderModal,
    TypingIndicators,
    Exports,
    ResellerOverview,
    PinButton,
    MuteButton,
    CloseIncidentButtonAndModal,
    DeleteIncidentButtonAndModal,
  },
  mixins: [UpdatesLastReadPointer, Dates, TimeAgoRefresher],
  data() {
    return {
      resellerUpdateKey: 1,

      selectedTab: "messages",
      rightbarCollapsed: true,

      isEditingName: false,
      newName: "",
      isSavingName: false,

      isAddingChecklist: false,
      isAddingStaffMembers: false,

      justCreatedIncident: false,

      notifyArrivalHeartbeatEnabled: false,
    };
  },
  watch: {
    "$route.params.id": "handleIncidentIdFromParamsChanged",

    incident: {
      handler(newVal, oldVal) {
        if (newVal) {
          if (!oldVal || oldVal.id !== newVal.id) {
            this.loadGroupMembers(newVal.id);
            this.loadMemberships(newVal.id);
            this.loadResponseTeams();
            this.loadSkills();
            this.loadAssignments();
            this.loadApiClientGroupLinks();
            this.setMetadataIds(newVal.metadataIds ? newVal.metadataIds : []);
            if (!this.isReseller) {
              this.loadTimeline({
                model: "incident",
                tenantId: this.tenantId,
                guid: newVal.id,
                ownerType: 3,
                ownerId: newVal.id,
                organisationId: newVal.ownerId,
                behaviour: 0,
              });
              this.loadQuickMessages();
            }

            if (!this.notifyArrivalHeartbeatEnabled) {
              if (this.amIPresentInGroup) {
                this.notifyArrivalHeartbeatEnabled = true;
                this.notifyArrival();
                this.updateMyLastReadPointer();
              }
            }

            if (!this.isArchived && this.adminMode && !this.amIPresentInGroup) {
              this.subscribeIfAdminMode();
            }
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("incidentViewer", {
      incident: "incident",
      isLoading: "isLoading",
      myPresenceId: "myPresenceId",
      membership: "membership",
      isWaitingForFirstResponder: "isWaitingForFirstResponder",
      amIPresentInGroup: "amIPresentInGroup",
      isArchived: "isArchived",
      adminMode: "adminMode",
      assignments: "assignments",
    }),

    ...mapGetters("timeline", {
      timelineGroupedByDate: "timelineGroupedByDate",
    }),

    ...mapGetters("identifier", {
      guid: "guid",
    }),

    ...mapGetters("missionControl", {
      isReseller: "isReseller",
    }),

    tenantId() {
      return this.$route.params.tenantId;
    },

    fromAdmin() {
      return this.$route.query.fromAdmin;
    },

    archivedBy() {
      if (this.isArchived && this.membership) {
        let member = _.find(this.membership, { id: this.incident.archivedBy });
        return member ? member.displayName : "";
      }
      return null;
    },

    tasksTabTitle() {
      let t = "Tasks";

      let incompleteTasks = _.filter(this.assignments, (task) => {
        return (
          task["content"]["$v"]["status"] ==
          assignments.getAssignmentNoneStatusEnumValue()
        );
      });

      if (incompleteTasks.length) {
        t = t + " (" + incompleteTasks.length + ")";
      }

      return t;
    },
  },
  methods: {
    ...mapActions("incidents", {
      loadGroupMembers: "loadGroupMembers",
      loadMemberships: "loadMemberships",
      updateIncidentGroupMember: "updateIncidentGroupMember",
    }),

    ...mapActions("incidentViewer", {
      enableAdminMode: "enableAdminMode",
      disableAdminMode: "disableAdminMode",
      loadIncident: "loadIncident",
      handleParticipantAdded: "handleParticipantAdded",
      loadResponseTeams: "loadResponseTeams",
      loadApiClientGroupLinks: "loadApiClientGroupLinks",
      loadAssignments: "loadAssignments",
      loadSkills: "loadSkills",
    }),

    ...mapActions("metadata", {
      setMetadataIds: "setMetadataIds",
      addGuidToMetadata: "addGuidToMetadata",
    }),

    ...mapActions("tasks", {
      loadTasks: "loadTasks",
    }),

    ...mapActions("timeline", {
      loadTimeline: "loadTimeline",
      loadQuickMessages: "loadQuickMessages",
    }),

    handleIncidentIdFromParamsChanged() {
      if (window.justCreatedIncident && window.justCreatedIncident == true) {
        this.justCreatedIncident = true;
        window.justCreatedIncident = null;
      }

      this.selectedTab = "messages";
      if (this.isReseller) {
        this.selectedTab = "reseller-overview";
      }
      // this.listenToSignalRControlMessage();
      // this.listenToSignalRParticipantAddedMessage();

      // Check if is admin mode
      if (this.$route.query.admin == "true") {
        this.enableAdminMode();
      } else {
        this.disableAdminMode();
      }

      let idFromUrl = this.$route.params.id;
      let isThisIncidentLoaded = this.incident && this.incident.id === idFromUrl;
      if (!isThisIncidentLoaded) {
        this.loadIncident({
          tenantId: this.tenantId,
          id: idFromUrl,
        });
      }
    },

    incrementResellerUpdateKey() {
      let vm = this;
      window.setTimeout(function () {
        vm.resellerUpdateKey += 1;
      }, 1000);
    },

    goBack() {
      if (this.isArchived) {
        this.$router.push("/admin/archived-incidents");
      } else {
        this.$router.push("/admin/incidents");
      }
    },

    expandRightSidebar() {
      this.rightbarCollapsed = false;
    },

    collapseRightSidebar() {
      this.rightbarCollapsed = true;
    },

    startEditingName() {
      this.isEditingName = true;
      this.newName = this.incident.displayName;
    },

    cancelEditingName() {
      this.isEditingName = false;
    },

    saveNewName() {
      let vm = this;

      if (!vm.newName.length) {
        return false;
      }

      if (!isNaN(vm.newName)) {
        vm.$message.error("New name cannot be only numbers");
        return false;
      }

      vm.isSavingName = true;

      let request;
      if (this.isArchived) {
        request = archivedIncidents.renameArchivedIncident(
          this.tenantId,
          this.incident.id,
          this.myPresenceId,
          this.newName
        );
      } else {
        request = incidents.renameIncident(
          this.tenantId,
          this.incident.id,
          this.myPresenceId,
          this.newName
        );
      }

      request
        .then(() => {
          vm.isSavingName = false;
          vm.isEditingName = false;
          vm.$message.success("Name updated successfully");
          vm.incident.displayName = this.newName;
        })
        .catch((e) => {
          console.log(e);
          vm.isSavingName = false;
          vm.$message.error("Error updating name");
        });
    },

    listenToSignalRControlMessage() {
      let vm = this;
      this.$signalR.on("ControlMessage", (controlMessage) => {
        console.log("Control Message:");
        console.log(controlMessage);

        let messageBody = JSON.parse(controlMessage["messageBody"]);
        // let groupId = messageBody["groupId"];
        let presenceId = messageBody["presenceId"];

        // if (groupId !== this.incident.id) {
        // return false
        // }

        // Set presence active
        if (controlMessage.messageType === 3) {
          this.setPresenceActive(presenceId);
        }

        // Set presence inactive
        if (controlMessage.messageType === 4) {
          this.setPresenceInactive(presenceId);
        }

        // Update group member
        if (controlMessage.messageType === 13) {
          let groupMemberDetails = messageBody.groupMemberDetails;
          if (groupMemberDetails.groupId === this.incident.id) {
            this.updateIncidentGroupMember(groupMemberDetails);
          }
        }

        // Closed
        if (controlMessage.messageType === 6) {
          if (controlMessage.targetGroupId === this.incident.id) {
            vm.$message.info("Incident has been closed");
            vm.$router.push("/incidents");
          }
        }
      });
    },

    listenToSignalRParticipantAddedMessage() {
      this.$signalR.on("AddParticiant", (participantAddedMessage) => {
        console.log("Participant Added");
        console.log(participantAddedMessage);

        let tenantId = participantAddedMessage.tenantId;
        let groupMemberDetails = participantAddedMessage.groupMemberDetails;

        let groupId = groupMemberDetails.groupId;
        if (tenantId === this.tenantId && groupId === this.incident.id) {
          this.handleParticipantAdded(groupMemberDetails);
        }
      });
    },

    addChecklist(checklist) {
      let vm = this;
      vm.isAddingChecklist = true;
      incidents
        .addChecklistToIncident(
          this.tenantId,
          this.incident.id,
          this.myPresenceId,
          checklist.id
        )
        .then(() => {
          vm.isAddingChecklist = false;
          vm.$message.success("Playbook added successfully");
          vm.addGuidToMetadata(checklist.id);
          vm.loadTasks();
        })
        .catch((e) => {
          console.log(e);
          vm.isAddingChecklist = false;
          vm.$message.error("Error adding checklist to incident");
        });
    },

    addMeToIncident() {
      if (this.isAddingStaffMembers) {
        return false;
      }
      this.addStaffMembers([this.myPresenceId], true);
    },

    addStaffMembers(staffMemberIds, addingMe = false) {
      let vm = this;
      vm.isAddingStaffMembers = true;
      incidents
        .addMembersToIncident(
          this.tenantId,
          this.incident.id,
          staffMemberIds,
          this.myPresenceId
        )
        .then(() => {
          vm.isAddingStaffMembers = false;
          vm.$message.success("Staff members added successfully");
          this.$nextTick(() => {
            this.selectedTab = "messages";
            if (addingMe) {
              this.disableAdminMode();
            }
            if (vm.isReseller) {
              vm.selectedTab = "reseller-overview";
              vm.loadGroupMembers(vm.incident.id);
              vm.loadMemberships(vm.incident.id);
              vm.loadResponseTeams();
              vm.incrementResellerUpdateKey();
            }
          });
        })
        .catch((e) => {
          console.log(e);
          vm.isAddingStaffMembers = false;
          vm.$message.error("Error adding staff members to incident");
        });
    },

    viewTasks() {
      this.selectedTab = "tasks";
    },

    navigateToEscalate() {
      this.selectedTab = "escalate";
    },

    handleResponseTeamAdded() {
      let vm = this;
      this.$nextTick(() => {
        this.selectedTab = "messages";
        if (this.isReseller) {
          vm.selectedTab = "reseller-overview";
          vm.loadGroupMembers(vm.incident.id);
          vm.loadMemberships(vm.incident.id);
          vm.loadResponseTeams();
          vm.incrementResellerUpdateKey();
        }
      });
    },

    async notifyArrival() {
      if (!this.notifyArrivalHeartbeatEnabled) {
        return false;
      }
      if (this.$route.name !== "View Incident") {
        this.notifyArrivalHeartbeatEnabled = false;
        return false;
      }
      try {
        let params = {
          InstallationId: this.guid,
          CurrentTenantId: this.tenantId,
          CurrentGroupId: this.incident.id,
          CurrentPresenceId: this.myPresenceId,
        };
        console.log("Sending NotifyClientAvailable heartbeat:");
        console.log(params);

        await this.$signalR.invoke("NotifyClientAvailable", params);
        console.log("Heartbeat sent successfully");
      } catch (err) {
        console.log("Error notifying arrived");
        console.log(err);
      }

      // Let's trigger another heartbeat in 60 seconds
      let vm = this;
      window.setTimeout(function () {
        vm.notifyArrival();
      }, 60000);
    },

    async notifyDepart() {
      try {
        let params = {
          InstallationId: this.guid,
          CurrentTenantId: null,
          CurrentGroupId: null,
          CurrentPresenceId: null,
        };
        console.log("Sending NotifyClientAvailable departure:");
        console.log(params);

        await this.$signalR.invoke("NotifyClientAvailable", params);
        console.log("Depart message sent successfully");
      } catch (err) {
        console.log("Error notifying departed");
        console.log(err);
      }
    },

    updateMyLastReadPointer() {
      let vm = this;
      this.updateIncidentGroupMember({
        presenceId: vm.myPresenceId,
        groupId: vm.incident.id,
        groupMemberDetails: {
          lastReadPointer: window.moment(),
        },
      });
      this.updateServerLastReadPointer(
        vm.tenantId,
        vm.myPresenceId,
        vm.incident.id
      );
    },

    subscribeIfAdminMode() {
      // TODO: add admin mode if statement (& not a member)
      try {
        this.$signalR.invoke("AddIncidentObserver", {
          TenantId: this.tenantId,
          GroupId: this.$route.params.id,
        });
      } catch (err) {
        console.log(err);
      }
    },

    unsubscribeIfAdminMode() {
      // TODO: add admin mode if statement (& not a member)
      try {
        this.$signalR.invoke("RemoveObserver", {
          TenantId: this.tenantId,
          GroupId: this.$route.params.id,
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
  created() {
    this.handleIncidentIdFromParamsChanged();
  },
  beforeDestroy() {
    this.notifyArrivalHeartbeatEnabled = false;
    // TODO: only send depart message if in group (i.e. if i'm an admin and viewing an incident that's not in my org, I should not send this as I'm not arrived!)
    if (this.amIPresentInGroup) {
      this.notifyDepart();
    }
    if (this.adminMode && !this.amIPresentInGroup) {
      this.unsubscribeIfAdminMode();
    }
  },
};
</script>

<style scoped lang="scss">
.loading-wrapper {
  margin-top: 50px;
}

.reseller-overview-inner {
  // background: #fff;
  // border-radius: 8px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.close-incident-button-wrapper {
  flex-grow: 1;
  text-align: right;
}

.no-incident-wrapper {
  margin-top: 50px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.incident,
.incident-inner,
#incident-show-inner-layout {
  height: 100%;
}

#incident-show-right-sidebar {
  box-shadow: 0 0 8px rgb(0 0 0 / 4%);
  z-index: 2;

  .ant-layout-sider-children {
    border-left: 1px solid #d9d9d9;
  }
}

.incident-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .top {
    flex-shrink: 1;

    .page-header {
      margin-bottom: 0;

      .page-title {
        flex: none;
      }

      .name-input {
        max-width: 300px;
      }

      .actions {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .actions-inner {
          max-width: 300px;
        }
      }

      .header-inline-actions {
        margin-left: 10px;

        .ant-btn {
          margin-right: 5px;
        }
      }
    }
  }

  .middle {
    flex-grow: 1;
    // overflow-y: scroll;
    overflow-y: hidden;
  }

  .bottom {
    padding-top: 15px;
    flex-shrink: 1;

    .bottom-toolbar-wrapper {
      display: flex;

      .is-archived {
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 0 8px rgb(0 0 0 / 4%);
        flex: 1;
      }

      .add-me-to-group {
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        display: flex;
        box-shadow: 0 0 8px rgb(0 0 0 / 4%);
        flex: 1;

        .left {
          flex-grow: 1;

          b {
            font-weight: 500;
          }
        }

        .right {
          flex-shrink: 1;
          padding-left: 15px;
          text-align: right;
        }
      }

      .left {
        flex-shrink: 1;
        padding-right: 15px;
        display: flex;
        align-items: center;
      }

      .right {
        flex-grow: 1;
      }
    }
  }
}

.pin-button {
  margin-right: 6px;
}
</style>