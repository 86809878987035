<template>
  <div class="export-list-item" @click.prevent="$emit('selected')">
    <div class="left">
      <presence-thumbnail
        :ownerId="result['ownerId']"
        size="large"
        :presenceId="result['createdBy']"
      ></presence-thumbnail>
    </div>
    <div class="middle" :key="timeAgoRefreshKey">
      <div class="name">
        Generated by {{ attemptGetName() }}
      </div>
      {{ displayTimeAgo(result["whenCreated"]) }}
    </div>
    <div class="right">
      <a-badge v-if="!whenProcessed" count="Processing"></a-badge>
      <a-icon :style="{'fontSize': '19px'}" v-else type="cloud-download" />
    </div>
  </div>
</template>

<script>
import PresenceThumbnail from "../../../../../components/Presences/PresenceThumbnail.vue";
import Dates from "../../../../../mixins/Dates";
import TimeAgoRefresher from "../../../../../mixins/TimeAgoRefresher";
import exports from "../../../../../api/exports";
import presences from "../../../../../api/presences";
const _ = require("lodash");
export default {
  components: { PresenceThumbnail },
  props: ["tenantId", "result", "membership"],
  mixins: [Dates, TimeAgoRefresher],
  data() {
    return {
      whenProcessed: null,
      updateAttempts: 0,

      isLoadingPresence: false,
      presence: null
    };
  },
  created() {
    this.whenProcessed = this.result["whenProcessed"];
    if (!this.whenProcessed) {
      this.updateWhenProcessed();
    }
    if (!this.member) {
      this.loadPresence();
    }
  },
  computed: {
    member() {
      if (this.presence) {
        return this.presence;
      }

      let presenceId = this.result['createdBy'];
      return _.find(this.membership, { id: presenceId });
    }
  },
  methods: {
    loadPresence() {
      let vm = this;
      vm.isLoadingPresence = true;
      presences.getPresence(vm.tenantId, vm.result['createdBy']).then(r => {
        vm.isLoadingPresence = false;
        vm.presence = r.data;
      }).catch(e => {
        console.log(e);
        vm.isLoadingPresence = false;
      });
    },

    attemptGetName() {
      return this.member ? this.member.displayName : "Unknown";
    },

    updateWhenProcessed() {
      let vm = this;
      if (!vm.whenProcessed && vm.updateAttempts < 10) {
        exports
          .getExport(this.tenantId, this.result.id)
          .then((r) => {
            vm.updateAttempts = vm.updateAttempts + 1;
            let newReport = r.data;
            if (newReport["whenProcessed"]) {
              vm.whenProcessed = newReport["whenProcessed"];
            } else {
              window.setTimeout(vm.updateWhenProcessed, 1000);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.export-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .middle {
    padding-left: 15px;
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  .name {
    font-weight: 500;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}
</style>