<template>
  <a-modal
    centered
    v-model="showModal"
    title="Escalate To Parent Org"
    :footer="null"
  >
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>

    <!-- Repsonse teams -->
    <div v-if="!isLoading">
      <!-- No response teams -->
      <a-alert
        v-if="responseTeamsToShow.length === 0"
        type="error"
        message="There are no response teams that can be added"
      />
      <!-- / No response teams -->

      <div class="response-teams-wrapper" v-if="responseTeamsToShow.length">
        <div
          @click.prevent="() => addResponseTeam(responseTeam)"
          class="response-team-list-item"
          v-for="responseTeam in responseTeamsToShow"
          :key="responseTeam.id"
        >
          {{ responseTeam.displayName }}
        </div>
      </div>
    </div>
    <!-- / Response teams -->
  </a-modal>
</template>

<script>
const _ = require('lodash');
import organisations from "../../api/organisations";
export default {
  props: ["visible", "tenantId", "orgId", "parentOrgId", "responseTeams"],

  data() {
    return {
      isLoading: false,
      allResponseTeams: [],
    };
  },

  watch: {
    showModal(isVisible) {
      if (isVisible) {
        this.fetch();
      }
    },
  },

  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      organisations
        .getSkillsForOrg(this.tenantId, this.orgId)
        .then((r) => {
          vm.isLoading = false;
          vm.allResponseTeams = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          // vm.$message.error("Error loading all response teams");
        });
    },

    addResponseTeam(responseTeam) {
      // let vm = this;
      this.$emit("add-response-team", responseTeam);
      this.$emit("close-modal");
    },
  },

  computed: {
    responseTeamsToShow() {
      let skillsDirectlyBelongingToParent = _.filter(this.allResponseTeams, rt => {
        return rt.ownerId == this.parentOrgId;
      });

      let selectedIds = _.map(this.responseTeams, "skillId");
      return _.filter(skillsDirectlyBelongingToParent, (team) => {
        return !selectedIds.includes(team.id);
      });
    },

    showModal: {
      get() {
        return this.visible;
      },
      set(val) {
        if (!val) {
          this.$emit("close-modal");
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.response-team-list-item {
  padding: 15px 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #eee;

  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0 !important;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}
</style>