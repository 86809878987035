<template>
  <a-modal
    class="waiting-for-responders-modal"
    v-model="visible"
    title="We're waiting for the first responder"
    :footer="null"
  >
    <!-- Active Members -->
    <div
      class="presence-thumbnail"
      v-for="member in activeMembersWithoutMe"
      :key="getActiveKey(member.id)"
    >
      <a-tooltip placement="left">
        <template slot="title">
          <span>{{ member.displayName }}</span>
        </template>
        <presence-thumbnail
          size="large"
          :owner-id="member.ownerId"
          :presence-id="member.id"
        >
        </presence-thumbnail>
      </a-tooltip>
    </div>
    <!-- / Active Members -->

    <!-- Inactive Members -->
    <div
      class="presence-thumbnail"
      v-for="member in inactiveMembersWithoutMe"
      :key="getInactiveKey(member.id)"
    >
      <a-tooltip placement="left">
        <template slot="title">
          <span>{{ member.displayName }}</span>
        </template>
        <presence-thumbnail
          size="large"
          :owner-id="member.ownerId"
          :presence-id="member.id"
        >
        </presence-thumbnail>
      </a-tooltip>
    </div>
    <!-- / Inactive Members -->
  </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require('lodash');
import PresenceThumbnail from "../../../components/Presences/PresenceThumbnail.vue";
export default {
  props: ['myPresenceId'],
  data() {
    return {
      visible: true,
    };
  },
  components: { PresenceThumbnail },
  computed: {
    ...mapGetters("incidentViewer", {
      activeMembers: "activeMembers",
      inactiveMembers: "inactiveMembers",
    }),

    activeMembersWithoutMe() {
      return _.filter(this.activeMembers, member => {
        return member.id !== this.myPresenceId;
      });
    },

    inactiveMembersWithoutMe() {
      return _.filter(this.inactiveMembers, member => {
        return member.id !== this.myPresenceId;
      });
    }
  },
  methods: {
    getActiveKey(i) {
      return "active-" + i;
    },

    getInactiveKey(i) {
      return "inactive-" + i;
    },
  },
};
</script>

<style lang="scss">
.waiting-for-responders-modal {
  .ant-modal-body {
    text-align: center;
  }

  .presence-thumbnail {
    margin-right: 10px;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>