<template>
  <div class="api-client-wrapper">
    <!-- Api client -->
    <div class="api-client" @click.prevent="openModal">
      <div class="left">
        <api-client-thumbnail
          :tenant-id="tenantId"
          :api-client="apiClient"
        ></api-client-thumbnail>
      </div>
      <div class="right" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <div class="right" v-if="!isLoading">
        <div class="title">
          {{ apiClient.displayName }}
        </div>
        <div class="description">
          {{ apiClient.headline }}
        </div>
      </div>
    </div>
    <!-- / Api client -->

    <!-- Modal -->
    <a-modal
      v-model="modalVisible"
      centered
      :title="apiClient.displayName"
      v-if="linkTarget"
      :ok-button-props="{ props: { disabled: !canShare } }"
      @ok="share"
    >
      <loading-screen :is-loading="isSaving"></loading-screen>
      <div v-for="(control, i) in controls" :key="i">
        <div v-if="shouldShowDebug(control)">{{ control }}</div>

        <link-target-string-control
          v-if="
            control['$t'] ==
            'Soteria.Models.LinkTargetStringControl, Soteria.Models'
          "
          :control="control"
          :incident="incident"
          :params="params"
        ></link-target-string-control>

        <link-target-lookup-control
          v-if="
            control['$t'] ==
            'Soteria.Models.LinkTargetLookupControl, Soteria.Models'
          "
          :control="control"
          :params="params"
          :tenant-id="tenantId"
          :org-id="orgId"
          :api-client-id="apiClient.id"
        >
        </link-target-lookup-control>
      </div>
    </a-modal>
    <!-- / Modal -->
  </div>
</template>

<script>
import apiClients from "../../../../../../api/api-clients";
import LoadingScreen from "../../../../../../components/LoadingScreen.vue";
import ApiClientThumbnail from "./ApiClientCard/ApiClientThumbnail.vue";
import LinkTargetLookupControl from "./ApiClientCard/LinkTargetLookupControl.vue";
import LinkTargetStringControl from "./ApiClientCard/LinkTargetStringControl.vue";
import { mapActions } from "vuex";
const _ = require("lodash");
export default {
  components: {
    LinkTargetStringControl,
    LinkTargetLookupControl,
    ApiClientThumbnail,
    LoadingScreen,
  },
  props: ["apiClient", "tenantId", "orgId", "incident", "myPresenceId"],
  data() {
    return {
      isLoadingLinkTarget: false,
      linkTarget: null,
      modalVisible: false,
      params: {},
      isSaving: false,
    };
  },
  computed: {
    isLoading() {
      return this.isLoadingLinkTarget;
    },

    controls() {
      if (this.linkTarget) {
        return this.linkTarget.linkParameters.controls;
      }
      return [];
    },

    canShare() {
      let canShare = true;
      _.each(this.controls, (control) => {
        if (control["$v"]["required"] && control["$v"]["required"] == true) {
          let controlName = control["$v"]["name"];
          let isProvided =
            this.params[controlName] &&
            this.params[controlName] !== "" &&
            this.params[controlName] !== null &&
            this.params[controlName] !== undefined;
            if (!isProvided) {
              canShare = false;
            }
        }
      });
      return canShare;
    },
  },
  created() {
    this.loadLinkTarget();
  },
  methods: {
    ...mapActions("incidentViewer", {
      loadApiClientGroupLinks: "loadApiClientGroupLinks",
    }),

    shouldShowDebug(control) {
      // return [
      // "Soteria.Models.LinkTargetStringControl, Soteria.Models",
      // ].includes(control["$t"]);
      return control == "nevershow";
    },

    loadLinkTarget() {
      let vm = this;
      vm.isLoadingLinkTarget = true;
      apiClients
        .getApiClientLinkTarget(this.apiClient.linkTargetId)
        .then((r) => {
          vm.isLoadingLinkTarget = false;
          vm.linkTarget = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingLinkTarget = false;
          // vm.$message.error("Error loading link target");
        });
    },

    openModal() {
      if (!this.linkTarget) {
        return false;
      }
      this.modalVisible = true;
    },

    closeModal() {
      this.modalVisible = false;
    },

    share() {
      if (!this.canShare) {
        this.$message.error('Please provide all required fields');
        return false;
      }

      let vm = this;
      vm.isSaving = true;
      let apiClientGroupLinkRequest = {
        OrgId: this.orgId,
        GroupId: this.incident.id,
        ApiClientId: this.apiClient.id,
        LinkedBy: this.myPresenceId,
        Parameters: this.params,
      };
      apiClients
        .addApiClientGroupLink(this.tenantId, apiClientGroupLinkRequest)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success(
            "Shared with " + this.apiClient.displayName + " successfully!"
          );
          vm.modalVisible = false;
          vm.loadApiClientGroupLinks();
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error sharing with " + this.apiClient.displayName);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.api-client {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #fff;
  margin-bottom: 15px;

  display: flex;
  .left {
    flex-shrink: 1;
    min-width: 50px;

    .anticon {
      font-size: 25px;
      color: #5155ea;
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    flex-grow: 1;
    padding-left: 15px;
  }

  .description {
    font-weight: 400;
    margin-top: 3px;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}
</style>