<template>
  <div class="archived-task">
    <!-- Task card -->
    <div class="task-card">
      <div class="left">
        <a-icon v-if="completed" class="complete" type="check-circle" />
        <a-icon
          v-if="notApplicable"
          class="not-applicable"
          type="minus-circle"
        />
        <a-icon
          v-if="incomplete"
          class="incomplete"
          type="exclamation-circle"
        />
      </div>
      <div class="center">
        <h3 class="title">
          {{ task.displayName }}
        </h3>
        <div class="description">
          {{ description }}
        </div>
        <div class="assigned-to" v-if="incomplete">
          <div v-if="isLoadingMembership">
            <a-spin size="small"></a-spin>
          </div>
          <div v-else>
            <presence-badge
              v-for="member in taskMembersToShow"
              :key="member.id"
              :presence-id="member.id"
              :owner-id="member.ownerId"
              :name="member.displayName"
            ></presence-badge>
            <a-badge
              v-if="howManyTaskMembersNotShown"
              :number-style="{
                color: 'rgba(0, 0, 0, 0.65)',
                background: '#f5c2cb',
              }"
              :count="howManyTaskMembersNotShownText"
            ></a-badge>
          </div>
        </div>
      </div>
    </div>
    <!-- / Task card -->
  </div>
</template>

<script>
import assignments from "../../../../../api/assignments";
import { mapGetters } from "vuex";
const _ = require("lodash");
import PresenceBadge from "../../../../../components/Presences/PresenceBadge.vue";

export default {
  props: ["tenantId", "incidentId", "task", "membership", "myPresenceId"],

  components: { PresenceBadge },

  data() {
    return {
      isLoadingMembership: false,
      taskMembership: [],
    };
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    taskMembersToShow() {
      if (this.howManyTaskMembersNotShown > 0) {
        return _.cloneDeep(this.taskMembership).splice(0, 5);
      }
      return this.taskMembership;
    },

    howManyTaskMembersNotShown() {
      if (this.taskMembership && this.taskMembership.length) {
        if (this.taskMembership.length > 5) {
          return this.taskMembership.length - 5;
        }
      }
      return 0;
    },

    howManyTaskMembersNotShownText() {
      return "+ " + this.howManyTaskMembersNotShown + " more";
    },

    incomplete() {
      return (
        this.task["content"]["$v"]["status"] ==
        assignments.getAssignmentNoneStatusEnumValue()
      );
    },

    notApplicable() {
      return (
        this.task["content"]["$v"]["status"] ==
        assignments.getAssignmentNotApplicableStatusEnumValue()
      );
    },

    completed() {
      return (
        this.task["content"]["$v"]["status"] ==
        assignments.getAssignmentCompletedStatusEnumValue()
      );
    },

    description() {
      return this.task["content"]["$v"]["description"];
    },
  },

  created() {
    this.getMembership();
  },

  methods: {
    formatMemberName(memberName, presenceId) {
      if (_.map(this.presences, "id").includes(presenceId)) {
        return "You (" + memberName + ")";
      }
      return memberName;
    },

    getMembership() {
      let vm = this;
      vm.isLoadingMembership = true;
      assignments
        .getMembershipForArchivedAssignment(this.tenantId, this.task.id)
        .then((r) => {
          vm.isLoadingMembership = false;
          vm.taskMembership = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingMembership = false;
          // vm.$message.error("Error loading taskmembership");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.task-card {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid #fff;
  margin-bottom: 15px;

  &.expanded {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .description {
    font-weight: 400;
    margin-top: 10px;
  }

  .assigned-to {
    font-weight: 400;
    margin-top: 14px;

    .ant-badge {
      margin-right: 5px;
    }
  }

  display: flex;
  .left {
    flex-shrink: 1;
    min-width: 50px;

    .anticon {
      font-size: 25px;
      color: #5155ea;

      &.complete {
        color: #00ff00;
      }

      &.incomplete {
        // color: red;
      }
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center {
    flex-grow: 1;
    padding-left: 15px;
  }
  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    padding-left: 15px;

    .mark-as-complete-button {
      margin-right: 7px;
    }
  }
}

.member-to-assign {
  padding: 15px 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #eee;

  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0 !important;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }
}

.loader {
  text-align: center;
  margin-top: 20px;
}

.presence-badge {
  margin-right: 8px;
}
</style>