<template>
  <div>
      <a-button :type="isMuted ? 'primary' : 'default'" :size="sizeToUse"
       @click.prevent.stop="toggle" icon="notification">Mute</a-button>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
export default {
    props: ['guid', 'size'],

    computed: {
        ...mapGetters('mutedGroups', {
            mutedGroupIds: 'mutedGroupIds'
        }),

        isMuted() {
            return this.mutedGroupIds.includes(this.guid)
        },

        sizeToUse() {
            if (this.size) {
                return this.size;
            }
            return 'normal';
        }
    },

    methods: {
        ...mapActions('mutedGroups', {
            toggleGroupMuted: 'toggleGroupMuted'
        }),

        toggle() {
            this.toggleGroupMuted(this.guid)
        }
    }
}
</script>

<style>

</style>