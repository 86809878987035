<template>
  <div class="delete-incident-button-wrapper">
    <a-button
      @click.prevent="openModal"
      class="delete-incident-button btn-rounded"
      size="large"
      block
      type="danger"
      >Delete Incident</a-button
    >

    <a-modal
      title="Delete Incident"
      v-model="modalVisible"
      @ok="deleteIncident"
      ok-text="Delete Incident"
      ok-type="danger"
    >
      <loading-screen :is-loading="isDeleting"></loading-screen>
      <p class="are-you-sure">Are you sure you want to delete this incident?</p>
    </a-modal>
  </div>
</template>

<script>
import LoadingScreen from "../../../components/LoadingScreen.vue";
import archivedIncidents from "../../../api/archived-incidents";
export default {
  props: ["incident", "myPresenceId", "tenantId", "adminMode"],
  components: { LoadingScreen },
  data() {
    return {
      modalVisible: false,
      isSaving: false,
    };
  },
  methods: {
    openModal() {
      this.modalVisible = true;
    },

    closeModal() {
      this.modalVisible = false;
    },

    deleteIncident() {
      let vm = this;
      vm.isSaving = true;

      archivedIncidents
        .deleteArchivedIncident(vm.tenantId, vm.incident.id, vm.myPresenceId)
        .then(() => {
          vm.isSaving = false;
          if (this.adminMode) {
            vm.$router.push("/admin/archived-incidents");
          } else {
            vm.$router.push("/closed-incidents");
          }
          vm.$message.success("Incident deleted successfully");
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error deleting incident");
        });
    },
  },
};
</script>

<style lang="scss">
.delete-incident-button-wrapper {
  .btn-rounded {
    margin-right: 20px;
  }

  .are-you-sure {
      text-align: center;
      font-weight: 500;
  }
}
</style>