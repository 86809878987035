<template>
  <div class="reseller-overview">
    <a-row :gutter="20" type="flex">
      <!-- Details -->
      <a-col :span="colSpan">
        <div class="col-inner">
          <div class="card">
            <h3>Details</h3>
            <div>
              <!-- Created by -->
              <div class="presence">
                <div class="left">
                  <presence-thumbnail
                    :owner-id="createdByPresence.ownerId"
                    :presence-id="createdByPresence.id"
                  >
                  </presence-thumbnail>
                </div>
                <div class="right">
                  <div>
                    Created by {{ getPresenceDisplayName(createdByPresence) }}
                    <div class="right-inner-detail" :key="timeAgoRefreshKey">
                      {{ displayTimeAgo(incident.created) }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- / Created by -->
              <!-- First responder -->
              <div class="presence" v-if="firstResponderMembership">
                <div class="left">
                  <presence-thumbnail
                    :owner-id="firstResponderMembership.ownerId"
                    :presence-id="firstResponderMembership.id"
                  >
                  </presence-thumbnail>
                </div>
                <div class="right">
                  <div>
                    First responded by
                    {{ getPresenceDisplayName(firstResponderMembership) }}
                    <div class="right-inner-detail">
                      After
                      {{
                        getTimeDifference(firstRespondedAt, incident.created)
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="not-yet-responded" v-else>Not yet responded</div>
              <!-- / First responder -->
            </div>
          </div>
        </div>
      </a-col>
      <!-- / Details -->

      <!-- Members -->
      <a-col :span="colSpan">
        <div class="col-inner">
          <div class="card">
            <h3>Members</h3>
            <div class="presences">
              <!-- Active -->
              <div
                class="presence"
                v-for="(member, i) in activeMembersWithMeOnTop"
                :key="getActiveKey(i)"
              >
                <div class="left">
                  <presence-thumbnail
                    :owner-id="member.ownerId"
                    :presence-id="member.id"
                  >
                  </presence-thumbnail>
                </div>

                <div class="right">
                  <div>
                    {{ getPresenceDisplayName(member) }}
                    <div class="right-inner-detail">
                      {{ getContextStringForMember(member) }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- / Active -->

              <!-- Inactive -->
              <div
                class="presence"
                v-for="(member, i) in inactiveMembersWithMeOnTop"
                :key="getInactiveKey(i)"
              >
                <div class="left">
                  <presence-thumbnail
                    style="opacity: 0.3"
                    :owner-id="member.ownerId"
                    :presence-id="member.id"
                  >
                  </presence-thumbnail>
                </div>

                <div class="right">
                  <div>
                    {{ getPresenceDisplayName(member) }}
                    <div class="right-inner-detail">
                      {{ getContextStringForMember(member) }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- / Inactive -->
            </div>
          </div>
        </div>
      </a-col>
      <!-- / Members -->
    </a-row>

    <a-row :gutter="20" type="flex">
      <!-- Response Teams -->
      <a-col :span="colSpan">
        <div class="col-inner">
          <div class="card">
            <h3>Response Teams</h3>
            <div class="loader" v-if="isLoadingSkills">
              <a-spin></a-spin>
            </div>

            <div v-if="!isLoadingSkills && responseTeams.length == 0">
              No response teams
            </div>

            <div class="response-teams" v-if="!isLoadingSkills">
              <div
                class="response-team"
                v-for="team in responseTeams"
                :key="team.id"
              >
                <div class="left">
                  <a-icon type="team" />
                </div>
                <div class="right">
                  {{ getResponseTeamName(team.skillId) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <!-- / Response Teams -->
      <!-- Actions -->
      <a-col :span="colSpan">
        <div class="col-inner">
          <div class="card">
            <h3>Actions</h3>
            <div>
              <close-incident-button-and-modal
                v-if="!isArchived"
                :incident="incident"
                :my-presence-id="myPresenceId"
                :tenant-id="tenantId"
              ></close-incident-button-and-modal>

              <delete-incident-button-and-modal
                v-if="isArchived"
                :incident="incident"
                :my-presence-id="myPresenceId"
                :tenant-id="tenantId"
              ></delete-incident-button-and-modal>
            </div>
          </div>
        </div>
      </a-col>
      <!-- / Actions -->
    </a-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import PresenceThumbnail from "../../../components/Presences/PresenceThumbnail.vue";
// import organisations from "../../../api/organisations";
import CloseIncidentButtonAndModal from "./CloseIncidentButtonAndModal.vue";
import DeleteIncidentButtonAndModal from "./DeleteIncidentButtonAndModal.vue";
import Dates from "../../../mixins/Dates";
import TimeAgoRefresher from "../../../mixins/TimeAgoRefresher";
export default {
  components: {
    PresenceThumbnail,
    CloseIncidentButtonAndModal,
    DeleteIncidentButtonAndModal,
  },
  mixins: [Dates, TimeAgoRefresher],
  computed: {
    ...mapGetters("incidentViewer", {
      activeMembers: "activeMembers",
      inactiveMembers: "inactiveMembers",
      membership: "membership",
      firstArrivalMembership: "firstArrivalMembership",
      firstArrivalGroupMember: "firstArrivalGroupMember",
      responseTeams: "responseTeams",
      groupMembers: "groupMembers",
      incident: "incident",
      tenantId: "tenantId",
      myPresenceId: "myPresenceId",
      isArchived: "isArchived",
      skills: "skills",
      isLoadingSkills: "isLoadingSkills",
      firstResponderMembership: "firstResponderMembership",
      firstRespondedAt: "firstRespondedAt",
    }),

    createdByPresence() {
      let vm = this;
      return _.find(this.membership, (member) => {
        return member.id === vm.incident.createdBy;
      });
    },

    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },

    myPresenceIds() {
      return _.map(this.presences, "id");
    },

    activeMembersWithMeOnTop() {
      let vm = this;
      if (vm.myPresenceIds) {
        let membersThatAreMe = _.filter(vm.activeMembers, (member) => {
          return vm.myPresenceIds.includes(member.id);
        });
        let membersThatAreNotMe = _.filter(vm.activeMembers, (member) => {
          return !vm.myPresenceIds.includes(member.id);
        });
        return _.flattenDeep([membersThatAreMe, membersThatAreNotMe]);
      }
      return vm.activeMembers;
    },

    inactiveMembersWithMeOnTop() {
      let vm = this;
      if (vm.myPresenceIds) {
        let membersThatAreMe = _.filter(vm.inactiveMembers, (member) => {
          return vm.myPresenceIds.includes(member.id);
        });
        let membersThatAreNotMe = _.filter(vm.inactiveMembers, (member) => {
          return !vm.myPresenceIds.includes(member.id);
        });
        return _.flattenDeep([membersThatAreMe, membersThatAreNotMe]);
      }
      return vm.inactiveMembers;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      // isLoadingSkills: false,
      // allSkills: [],
    };
  },
  created() {
    // this.getAllSkills();
  },
  methods: {
    getActiveKey(i) {
      return "active-" + i;
    },

    getInactiveKey(i) {
      return "inactive-" + i;
    },

    // getAllSkills() {
    //   let vm = this;
    //   vm.isLoadingSkills = true;
    //   organisations
    //     .getSkillsForOrg(this.tenantId, this.incident.ownerId)
    //     .then((r) => {
    //       vm.isLoadingSkills = false;
    //       vm.allSkills = r.data;
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //       // vm.isLoadingSkills = false;
    //     });
    // },

    getResponseTeamName(skillId) {
      let team = _.find(this.skills, { id: skillId });
      return team ? team.displayName : "Unknown";
    },

    getPresenceDisplayName(presence) {
      if (presence.id === this.myPresenceId) {
        return "You (" + presence.displayName + ")";
      }
      return presence.displayName;
    },

    getContextStringForMember(member) {
      let groupMember = _.find(this.groupMembers, { presenceId: member.id });
      if (groupMember) {
        // Created
        if (member.id == this.incident.createdBy) {
          return "Created " + this.displayTimeAgo(this.incident.created);
        }

        // Has arrived
        if (groupMember.firstArrival) {
          return (
            "Arrived after " +
            this.getTimeDifference(
              groupMember.firstArrival,
              this.incident.created
            )
          );
        }
        // Not arrived
        return "Not seen yet";
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.reseller-overview {
  .col-inner {
    padding-bottom: 20px;
  }

  .card,
  .col-inner {
    height: 100%;
  }

  .card {
    background: #fff;
    padding: 25px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 20px;

    &.loading {
      text-align: center;
    }

    &.flex {
      display: flex;
    }

    .left {
      flex-shrink: 1;
      padding-right: 30px;
    }

    .right {
      flex-grow: 1;
    }

    .ant-avatar-lg {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }

    h3 {
      margin-bottom: 15px;
    }

    &.basic-details {
      h3 {
        margin-bottom: 9px;
      }
    }

    &.granted-permissions {
      ul {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &.support-information {
      .support-information-buttons {
        padding-top: 10px;
        .ant-btn {
          margin-right: 15px;
        }
      }
    }
  }
}

.header {
  padding-bottom: 5px;
}

.section {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0 !important;
  }
}

.expanded-sidebar {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  max-height: 100%;
  height: 100%;
  // overflow-y: scroll;
  display: flex;
  flex-direction: column;

  .top {
    flex-grow: 1;
    overflow-y: scroll;
  }

  .bottom {
    flex-shrink: 1;
  }
}

.presence {
  margin-bottom: 10px;
  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  .right {
    padding-left: 15px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-weight: 500;
  }
}

.response-team {
  margin-bottom: 15px;
  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #5155ea;
  }

  .right {
    padding-left: 15px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-weight: 500;
  }
}

.right-inner-detail {
  font-weight: 400;
  margin-top: 3px;
  margin-bottom: 6px;
  font-size: 13px;
}

.not-yet-responded {
  color: #ff4d4f;
  padding-top: 7px;
}
</style>