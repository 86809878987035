<template>
  <div class="exports-section">
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>

    <!-- No exports -->
    <a-alert
      v-if="!isLoading && exports.length === 0"
      type="info"
      message="No exports to show"
    />
    <!-- / No exports -->

    <!-- Exports -->
    <a-row v-if="exports.length" :gutter="20" type="flex">
      <a-col :span="colSpan" v-for="(result, i) in exports" :key="i">
        <export-list-item
          @selected="attemptDownload(result)"
          :tenant-id="tenantId"
          :result="result"
          :membership="membership"
        ></export-list-item>
      </a-col>
    </a-row>
    <!-- / Exports -->

    <a-button
      class="btn-rounded create-export-button"
      icon="plus"
      size="large"
      :disabled="isCreatingExport"
      type="primary"
      @click.prevent="createExport"
      >Create Export</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import exports from "../../../../api/exports";
import ExportListItem from "./Exports/ExportListItem.vue";
export default {
  data() {
    return {
      isLoading: false,
      exports: [],

      isCreatingExport: false,
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  components: { ExportListItem },

  computed: {
    ...mapGetters("incidentViewer", {
      tenantId: "tenantId",
      incident: "incident",
      myPresenceId: "myPresenceId",
      membership: "membership",
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      exports
        .getExportsForIncident(this.tenantId, this.incident.id)
        .then((r) => {
          vm.isLoading = false;
          vm.exports = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
        });
    },

    createExport() {
      let vm = this;

      if (vm.isCreatingExport) {
        return false;
      }

      vm.isCreatingExport = true;
      exports
        .addIncidentExport(vm.tenantId, {
          orgId: vm.incident.ownerId,
          createdBy: vm.myPresenceId,
          incidentId: vm.incident.id,
          includeMediaSeparately: true
        })
        .then((r) => {
          vm.isCreatingExport = false;
          vm.exports.push(r.data);
        })
        .catch((e) => {
          console.log(e);
          vm.isCreatingExport = false;
          vm.$message.error("Error creating export");
        });
    },

    attemptDownload(result) {
      let vm = this;
      exports
        .getExportUrl(this.tenantId, result.id)
        .then((r) => {
          let data = r.data;
          if (data.url) {
            window.open(data.url, "_blank");
          } else {
            vm.$message.error("Cannot download report");
          }
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error downloading report");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.exports-section {
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  // No scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  .loader {
    text-align: center;
    margin-top: 20px;
  }

  .create-export-button {
    margin-top: 20px;
  }

  .export-list-item {
    margin-bottom: 15px;
  }
}
</style>