<template>
  <div class="shared-with-card">
    <div class="left">
      <api-client-thumbnail
        :tenant-id="tenantId"
        :api-client="apiClient"
      ></api-client-thumbnail>
    </div>
    <div class="right">
      <div class="title">
        {{ linkedBy }}
      </div>
      <div class="description">
        {{ apiClientGroupLink.statusMessage }}
      </div>
      <div class="timestamp" :key="timeAgoRefreshKey">
        Linked {{ displayTimeAgo(apiClientGroupLink.lastUpdated) }}
      </div>
    </div>
  </div>
</template>

<script>
import ApiClientThumbnail from "../ShareWith/ApiClientCard/ApiClientThumbnail.vue";
import Dates from "../../../../../../mixins/Dates";
import TimeAgoRefresher from "../../../../../../mixins/TimeAgoRefresher";
const _ = require('lodash');
import {mapGetters} from 'vuex';
export default {
  props: ["apiClientGroupLink", "tenantId"],
  components: { ApiClientThumbnail },
  mixins: [Dates, TimeAgoRefresher],
  computed: {
    ...mapGetters('incidentViewer', {
      myPresenceId: 'myPresenceId',
      membership: 'membership'
    }),

    apiClient() {
      return {
        id: this.apiClientGroupLink.apiClientId,
      };
    },

    linkedBy() {
      let linkedById = this.apiClientGroupLink.linkedBy;
      let presence = _.find(this.membership, {id: linkedById});
      if (!presence) {
        return 'Unknown';
      }
      if (linkedById == this.myPresenceId) {
        return 'You (' + presence.displayName + ')'
      }
      return presence.displayName;
    }
  },
};
</script>

<style scoped lang="scss">
.shared-with-card {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid #fff;
  display: flex;
  margin-bottom: 15px;

  .left {
    flex-shrink: 1;
    min-width: 50px;

    .anticon {
      font-size: 25px;
      color: #5155ea;
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    flex-grow: 1;
    padding-left: 15px;
  }

  .description, .timestamp {
    font-weight: 400;
    margin-top: 3px;
  }
}
</style>