<template>
  <div class="help-videos-section">
    <help-video-grid @video-selected="setVideo" :col-span="6"></help-video-grid>

    <a-modal
      class="fullscreen-modal"
      v-model="modalVisible"
      centered
      :title="modalTitle"
      :footer="false"
    >
      <video
        controls
        width="100%"
        v-if="video"
        class="video-viewer"
        autoplay
        loop
      >
        <source :src="video.media.publicUrl" :type="video.media.contentType" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import timelines from "../../../../api/timelines";
import HelpVideoGrid from "../../../../components/HelpVideos/HelpVideoGrid.vue";
export default {
  props: ["colSpan"],
  data() {
    return {
      video: null,
    };
  },
  components: { HelpVideoGrid },
  computed: {
    ...mapGetters("incidentViewer", {
      tenantId: "tenantId",
      incident: "incident",
      myPresenceId: "myPresenceId",
    }),

    ...mapGetters("timeline", {
      ownerType: "ownerType",
    }),

    modalVisible: {
      get() {
        return this.video !== null;
      },

      set(val) {
        if (!val) {
          this.video = null;
        }
      },
    },

    modalTitle() {
      return this.video ? this.video.displayName : "-";
    },
  },
  methods: {
    setVideo(video) {
      // let vm = this;
      this.video = video;

      if (
        this.incident.createdBy == this.myPresenceId &&
        (!this.incident.metadataIds ||
          (this.incident.metadataIds &&
            !this.incident.metadataIds.includes(this.video.id)))
      ) {
        let params = {
          Behaviour: 0,
          CreatedByPresenceId: this.myPresenceId,
          OwnerId: this.incident.id,
          OwnerType: this.ownerType,
          MetadataId: this.video.id,
          Content: {
            $t: "Soteria.Models.MarkdownChatEntry, Soteria.Models",
            $v: {
              Text: "WATCHING video: " + this.video.displayName,
            },
          },
        };
        timelines
          .postTimelineEntry(this.tenantId, params)
          .then(() => {})
          .catch((e) => {
            console.log(e);
            // vm.$message.error('Error posting video watched message to timeline');
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.help-videos-section {
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.video-viewer {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
}
</style>