<template>
  <div>
    <h3 v-if="apiClientGroupLinks.length">Shared</h3>

    <!-- Is loading -->
    <div class="loader" v-if="isLoadingApiClientGroupLinks">
      <a-spin></a-spin>
    </div>
    <!-- / Is loading -->

    <!-- Shared -->
    <div class="shared-with">
      <shared-with-card
        v-for="apiClientGroupLink in apiClientGroupLinks"
        :key="apiClientGroupLink.id"
        :tenant-id="tenantId"
        :api-client-group-link="apiClientGroupLink"
      ></shared-with-card>
    </div>
    <!-- / Shared -->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SharedWithCard from "./SharedWith/SharedWithCard.vue";
export default {
  components: { SharedWithCard },
  computed: {
    ...mapGetters("incidentViewer", {
      isLoadingApiClientGroupLinks: "isLoadingApiClientGroupLinks",
      apiClientGroupLinks: "apiClientGroupLinks",
      tenantId: "tenantId"
    }),
  },
};
</script>

<style>
</style>