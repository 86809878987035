<template>
  <div class="close-incident-button-wrapper">
    <a-button @click.prevent="openModal" class="close-incident-button btn-rounded" size="large" block
      type="danger">Close Incident</a-button>

    <a-modal title="Close Incident" v-model="modalVisible" @ok="closeIncident" ok-text="Close Incident"
      :ok-button-props="{ props: { disabled: !closureMessage.trim() } }">
      <loading-screen :is-loading="isSaving"></loading-screen>

      <!-- Is loading API clients -->
      <div class="close-incident-loader" v-if="isLoadingApiClients">
        <a-spin></a-spin>
      </div>
      <!-- / Is loading API clients -->

      <!-- Api Clients loaded -->
      <div v-if="!isLoadingApiClients">

        <!-- You haven't shared -->
        <div class="close-incident-have-not-shared-with">
          <div class="left">
            <p>You haven't shared this incident with:</p>
            <a-badge v-for="(apiClient, i) in apiClientsToShow" :key="`api-client-${i}`"
              :count="apiClient.displayName"></a-badge>
          </div>
          <div class="right">
            <a-button @click.prevent="share" type="primary" icon="share-alt">Share</a-button>
          </div>
        </div>
        <!-- / You haven't shared -->

        <p>Closed incidents are accessed via the admin portal</p>
        <a-textarea rows="4" v-model="closureMessage" :placeholder="placeholder" allow-clear />
      </div>
      <!-- / Api Clients loaded -->
    </a-modal>
  </div>
</template>

<script>
const _ = require('lodash');
import apiClients from "../../../api/api-clients";
import { mapActions, mapGetters } from "vuex";
import LoadingScreen from "../../../components/LoadingScreen.vue";
import incidents from "../../../api/incidents";
export default {
  props: ["incident", "myPresenceId", "tenantId"],
  components: { LoadingScreen },
  data() {
    return {
      modalVisible: false,
      closureMessage: "",
      isSaving: false,

      isLoadingApiClients: false,
      apiClients: []
    };
  },
  methods: {
    ...mapActions('incidents', {
      disposeOfIncident: 'disposeOfIncident'
    }),

    share() {
      this.modalVisible = false;
      this.$emit('navigate-to-escalate');
    },

    openModal() {
      this.modalVisible = true;
      this.loadApiClients();
    },

    loadApiClients() {
      let vm = this;
      vm.isLoadingApiClients = true;
      apiClients
        .getApiClients(this.tenantId, this.incident.ownerId)
        .then((r) => {
          vm.isLoadingApiClients = false;
          vm.apiClients = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingApiClients = false;
          // vm.$message.error("Error loading API clients");
        });
    },

    closeIncident() {
      let vm = this;
      vm.isSaving = true;

      let closeIncidentRequest = {
        Id: vm.incident.id,
        ByPresenceId: vm.myPresenceId,
        ClosingNote: vm.closureMessage
      };

      incidents.archiveIncident(vm.tenantId, closeIncidentRequest).then(() => {
        vm.isSaving = false;
        vm.disposeOfIncident(vm.incident.id);
        vm.$router.push('/incidents');
        vm.$message.success('Incident closed successfully');
      }).catch(e => {
        console.log(e);
        vm.isSaving = false;
        vm.$message.error('Error closing incident');
      });
    },
  },
  computed: {
    ...mapGetters("incidentViewer", {
      tenantId: "tenantId",
      incident: "incident",
      sharedWithApiClientIds: "sharedWithApiClientIds",
      myPresenceId: "myPresenceId"
    }),

    placeholder() {
      return `Please add closure message for "${this.incident.displayName}"`;
    },

    apiClientsToShow() {
      let vm = this;
      let withLinkTarget = _.filter(this.apiClients, (client) => {
        return client.linkTargetId;
      });
      let alsoNotShared = _.filter(withLinkTarget, (client) => {
        return !vm.sharedWithApiClientIds.includes(client.id);
      });
      return alsoNotShared;
    },
  },
};
</script>

<style lang="scss">
.close-incident-loader {
  text-align: center;
}

.close-incident-have-not-shared-with {
  margin-bottom: 30px;
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;

  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  p {
    margin-bottom: 10px;
    // font-size: 13px;
  }

  .ant-badge {
    margin-right: 5px;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
</style>