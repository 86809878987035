<template>
  <div class="archived-tasks-section">
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>

    <!-- Show completed -->
    <div class="should-show-completed">
      <div class="left"></div>
      <div class="right">
        <div class="should-show-completed-label">Show completed?</div>
        <a-switch v-model="shouldShowCompleted" />
      </div>
    </div>
    <!-- / Show completed -->

    <!-- No tasks -->
    <a-alert
      v-if="!isLoading && assignments.length === 0"
      type="info"
      message="No tasks were added to this incident"
    />
    <!-- / No tasks -->

    <!-- Tasks -->
    <div class="tasks" v-if="!isLoading">
      <archived-task
        v-for="task in assignmentsToShow"
        :key="task.id"
        :tenant-id="tenantId"
        :incident-id="incident.id"
        :task="task"
        :membership="membership"
        :my-presence-id="myPresenceId"
      ></archived-task>
    </div>
    <!-- / Tasks -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import archivedIncidents from "../../../../api/archived-incidents";
import assignments from "../../../../api/assignments";
const _ = require("lodash");
import ArchivedTask from "./ArchivedTasks/ArchivedTask.vue";
export default {
  components: { ArchivedTask },

  data() {
    return {
      isLoading: false,
      assignments: [],
      shouldShowCompleted: false,
    };
  },

  computed: {
    ...mapGetters("incidentViewer", {
      tenantId: "tenantId",
      incident: "incident",
      membership: "membership",
      myPresenceId: "myPresenceId",
    }),

    assignmentsToShow() {
      let ordered = _.orderBy(this.assignments, "order");
      return _.filter(ordered, (task) => {
        if (this.shouldShowCompleted) {
          return true;
        }
        return (
          task["content"]["$v"]["status"] !==
          assignments.getAssignmentCompletedStatusEnumValue()
        );
      });
    },
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      let vm = this;
      vm.isLoading = true;
      archivedIncidents
        .getArchivedAssignmentsForArchivedIncident(
          this.tenantId,
          this.incident.id
        )
        .then((r) => {
          vm.isLoading = false;
          vm.assignments = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.archived-tasks-section {
  .loader {
    text-align: center;
    margin-top: 20px;
  }

  .tasks-section {
    height: 100%;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    // No scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .should-show-completed {
    .should-show-completed-label {
      margin-right: 10px;
      display: inline;
    }

    display: flex;
    margin-bottom: 20px;
    .left {
      flex-grow: 1;
    }
    .right {
      flex-shrink: 1;
    }
  }
}
</style>